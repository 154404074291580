<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/question-group' }">
        <app-i18n
          code="entities.questionGroup.menu"
        ></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.questionGroup.view.title"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.questionGroup.view.title"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-question-group-view-toolbar
        v-if="record"
      ></app-question-group-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-text
          :label="fields.id.label"
          :value="presenter(record, 'id')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.questionGroup.label"
          :value="presenter(record, 'questionGroup')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.groupStatus.label"
          :value="presenter(record, 'groupStatus')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import QuestionGroupViewToolbar from '@/modules/question-group/components/question-group-view-toolbar.vue';
import { QuestionGroupModel } from '@/modules/question-group/question-group-model';

const { fields } = QuestionGroupModel;

export default {
  name: 'app-question-group-view-page',

  props: ['id'],

  components: {
    [QuestionGroupViewToolbar.name]: QuestionGroupViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'questionGroup/view/record',
      loading: 'questionGroup/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'questionGroup/view/doFind',
    }),

    presenter(record, fieldName) {
      return QuestionGroupModel.presenter(
        record,
        fieldName,
      );
    },
  },
};
</script>
